import { Box, BoxProps, Typography } from "@mui/material";
import { makeStyles } from "@lib/themes";

interface MessageProps extends BoxProps {
  text: string;
  type?: "error" | "success";
}

const useStyles = makeStyles((theme) => ({
  error: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    backgroundColor: (p: { type: string }) =>
      p.type === "error"
        ? theme.palette.error.main
        : theme.palette.success.main,
    borderRadius: theme.shape.borderRadius,
    color: "white",
  },
}));

export default function Message({
  text,
  type = "error",
  children,
  ...props
}: MessageProps) {
  const classes = useStyles({ type });
  return (
    <Box className={classes.error} {...props}>
      <Typography variant="body1">
        {text}
        {children}
      </Typography>
    </Box>
  );
}
