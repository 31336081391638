import React from "react";

import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import { Box, Link, Typography } from "@mui/material";
import NextLink from "next/link";
import ExpandLess from "@public/chevron-left.svg";
import { useAuthPageStyles } from "@lib/hooks/styles";

interface LoginInfoProps {
  floatRight?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: 0,
      left: 0,
      padding: "80px",
      maxWidth: 720,
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 58,
    },
  },
  floatRight: {
    right: "0 !important",
    left: "unset",
  },
  logo: {
    marginBottom: 80,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  title: {
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      marginBottom: 24,
    },
  },
  subtitle: {
    marginBottom: 16,
    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
  },
}));

const LoginInfo = ({ floatRight }: LoginInfoProps) => {
  const classes = useStyles();
  const classes2 = useAuthPageStyles();

  return (
    <Box className={`${classes.root} ${floatRight ? classes.floatRight : ""}`}>
      <NextLink href="/">
        <Box className={classes2.backButton}>
          <ExpandLess className={classes2.backButtonIcon} />
          <Typography display="flex" variant="body2">
            Back to
            <Link
              className={classes2.navItem}
              color="inherit"
              variant="body1"
              href="/">
              <Typography variant="body1">Home Page</Typography>
            </Link>
          </Typography>
        </Box>
      </NextLink>

      <Box className={classes.logo}>
        <NextLink href="/">
          <Link href="/">
            <img src="/logo.png" height="71px" />
          </Link>
        </NextLink>
      </Box>
      <Typography className={classes.title} variant="h2">
        Data Driven Decisions for Your Crypto Holdings
      </Typography>
      <Typography className={classes.subtitle} variant="h4">
        Make informed decisions about your crypto holdings using enhanced data
        from the top 20 exchanges.
      </Typography>
      <Typography variant="h4">
        Access real-time data, historical data, liquidity, charts, comparison
        and more.
      </Typography>
    </Box>
  );
};

export default LoginInfo;
