import { FormProvider, FormTextField } from "@components/form";
import { useAuthPageStyles } from "@lib/hooks/styles";
import { Box, Link, Typography } from "@mui/material";
import { makeStyles } from "@lib/themes";
import cx from "classnames";
import React from "react";
import * as yup from "yup";
import NextLink from "next/link";
import MainButton from "@components/MainButton";

interface LoginFormProps {
  buttonTitle?: string;
  onSubmit: (values: { [key: string]: string }) => void;
}

const LoginFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(4),
});

const defaultValues = {
  email: "",
  password: "",
};

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
}));

export function LoginForm({
  buttonTitle = "Sign In",
  onSubmit,
}: LoginFormProps) {
  const classes = useStyles();
  const authPageClasses = useAuthPageStyles();

  return (
    <FormProvider
      FormProps={{ className: authPageClasses.form }}
      ValidationSchema={LoginFormSchema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}>
      {({ formState }) => (
        <Box width="100%" className={classes.root}>
          <Box className={authPageClasses.formElement}>
            <FormTextField
              name="email"
              type="text"
              label="Email"
              fullWidth
              required
              disabled={formState.isSubmitting}
            />
          </Box>

          <Box className={authPageClasses.formElement}>
            <FormTextField
              name="password"
              type="password"
              label="Password"
              fullWidth
              disabled={formState.isSubmitting}
            />
          </Box>

          <Box className={authPageClasses.formElement}>
            <Box className={authPageClasses.submitContainer}>
              <Typography variant="body2">
                <NextLink
                  href="/forgot-password"
                  className={authPageClasses.disabled}>
                  <Link
                    aria-disabled
                    className={cx(
                      authPageClasses.navItem,
                      authPageClasses.disabled,
                    )}
                    color="inherit"
                    variant="body1"
                    href="/forgot-password">
                    Forgot Password?
                  </Link>
                </NextLink>
              </Typography>
              <Box className={authPageClasses.mainButton}>
                <MainButton
                  title={buttonTitle}
                  disabled={
                    !formState.isValid ||
                    !formState.isDirty ||
                    formState.isSubmitting ||
                    true // TODO: remove this line
                  }
                  type="submit"
                  buttonPadding={"12px 90px"}
                />
              </Box>

              {/* <Button
                className={authPageClasses.button}
                disabled={
                  !formState.isValid ||
                  !formState.isDirty ||
                  formState.isSubmitting
                }
                type="submit">
                <Typography variant="body1" color="textPrimary">
                  {buttonTitle}
                </Typography>
              </Button> */}
            </Box>
          </Box>
        </Box>
      )}
    </FormProvider>
  );
}
