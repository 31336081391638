import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FilledInputProps, TextFieldProps } from "@mui/material";
import TextField from "@components/TextField";
import getErrorMessages from "./helpers/get-error-message";

export interface TextFieldElementProps extends Omit<TextFieldProps, "name"> {
  // validation?: any;
  name: string;
  parseError?: (errorType: string) => string;
  InputProps?: FilledInputProps;
  disabled?: boolean;
  formLabel?: boolean;
}

export function FormTextField({
  // validation = {},
  required,
  name,
  parseError,
  ...rest
}: TextFieldElementProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessages = getErrorMessages(name, errors, parseError);

  return (
    <Controller
      name={name}
      rules={{ required: required && "This field is required" }}
      control={control}
      // rules={validation}
      render={({ field }) => (
        <TextField
          {...field}
          showErrorMessage={false}
          errorMessage={errorMessages}
          {...rest}
        />
      )}
    />
  );
}
