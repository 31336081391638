export enum Actions {
  SetResult,
  SetError,
  StartLoading,
  SentEmail,
  Login,
}

export interface State {
  loginError: string;
  loginResult: string;
  loading: boolean;
  pageType: PageTypes;
}

export interface Action {
  type: Actions;
  value?: string;
}

export enum PageTypes {
  Login,
  EmailSent,
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case Actions.SetResult: {
      state.loginError = null;
      state.loginResult = action.value;
      state.loading = false;
      return { ...state };
    }
    case Actions.SetError: {
      state.loginError = action.value;
      state.loginResult = null;
      state.loading = false;
      return { ...state };
    }
    case Actions.StartLoading: {
      state.loginError = null;
      state.loginResult = null;
      state.loading = true;
      return { ...state };
    }
    case Actions.SentEmail: {
      state.loginError = null;
      state.loginResult = null;
      state.loading = false;
      state.pageType = PageTypes.EmailSent;
      return { ...state };
    }
    case Actions.Login: {
      state.pageType = PageTypes.Login;
      return { ...state };
    }
  }
}

export function getInitialState({ pageType }: { pageType: PageTypes }): State {
  return {
    loginError: null,
    loginResult: null,
    loading: false,
    pageType,
  };
}
