// import React from "react";
// import { Box, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@lib/themes";
// import Image from "next/image";

interface SocialsProps {
  signInWithGoogle?: () => void;
  signInWithTwitter?: () => void;
  signInWithFacebook?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  divider: {
    height: 1,
    borderBottom: "1px solid #2A5369",
    width: "30%",
  },
}));

export default function Socials(props: SocialsProps) {
  return null;
  // const classes = useStyles();
  // return (
  //   <React.Fragment>
  //     <Box className={classes.title} mb={1.5}>
  //       <Box className={classes.divider}></Box>
  //       <Typography variant="body2" color="textSecondary">
  //         or sign in with
  //       </Typography>
  //       <Box className={classes.divider}></Box>
  //     </Box>

  //     <Box className={classes.root} mb={3}>
  //       <IconButton
  //         color="primary"
  //         aria-label="login google"
  //         component="span"
  //         onClick={() => props.signInWithGoogle && props.signInWithGoogle()}>
  //         <Image src="/google.svg" width={24} height={24} />
  //       </IconButton>
  //       {false && (
  //         <IconButton
  //           color="primary"
  //           aria-label="login twitter"
  //           onClick={() => props.signInWithTwitter && props.signInWithTwitter()}
  //           component="span">
  //           <Image src="/twitter.svg" width={24} height={24} />
  //         </IconButton>
  //       )}
  //       {/* <IconButton
  //         color="primary"
  //         aria-label="login facebook"
  //         onClick={() => props.signInWithFacebook && props.signInWithFacebook()}
  //         component="span">
  //         <Image src="/facebook.svg" width={24} height={24} />
  //       </IconButton> */}
  //       {false && (
  //         <>
  //           <IconButton
  //             color="primary"
  //             aria-label="login twitter"
  //             onClick={() =>
  //               props.signInWithTwitter && props.signInWithTwitter()
  //             }
  //             component="span">
  //             <Image src="/twitter.svg" width={24} height={24} />
  //           </IconButton>
  //           {/* <IconButton
  //             color="primary"
  //             aria-label="login facebook"
  //             onClick={() =>
  //               props.signInWithFacebook && props.signInWithFacebook()
  //             }
  //             component="span">
  //             <Image src="/facebook.svg" width={24} height={24} />
  //           </IconButton> */}
  //         </>
  //       )}
  //     </Box>
  //   </React.Fragment>
  // );
}
