import { FieldError } from "react-hook-form";

const getErrorMessages = (
  name: string,
  errors: Record<string, FieldError>,
  parseError?: (errorType: string) => string,
) => {
  const fieldError = name in errors ? errors[name] : undefined;
  const errorType: string | undefined = fieldError?.type;
  const errorMessage: string | undefined = fieldError?.message;
  if (Array.isArray(fieldError)) {
    console.error("Unexpected field error", fieldError);
  }
  if (!errorType) return "";
  return parseError ? parseError(errorType) : `${errorMessage}`;
};

export default getErrorMessages;
