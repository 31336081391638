import cx from "classnames";
import { useReducer, useEffect } from "react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { Typography, Box, Paper, Link } from "@mui/material";

import { Routes } from "@lib/routes";
import BackgroundPattern from "@components/BackgroundPattern";
import Socials from "@components/Socials";
import Message from "@components/Message";
import { AUTH_ERROR_CODE, useAuth } from "@components/app";
import { useAuthPageStyles } from "@lib/hooks/styles";
import { useSnackbars } from "@components/use-snackbar";
import { LOGIN_SEO } from "@lib/seo";
import fetcher from "@lib/fetcher";
import { AppHead } from "@components/AppHead";
import MainButton from "@components/MainButton";
import LoginInfo from "@components/LoginInfo";

import { LoginForm } from "./login-form";
import { reducer, getInitialState, Actions, PageTypes } from "./reducer";

// TODO: adjust Alert styles
export default function LoginPage() {
  const classes = useAuthPageStyles();
  const router = useRouter();
  const { user, signIn, signInWithGoogle, signInWithFacebook } = useAuth();
  const { createSnackbar } = useSnackbars();
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState({
      pageType: PageTypes.Login,
    }),
  );
  useEffect(() => {
    if (user) {
      const redirect = router.query.redirect as string | undefined;

      if (typeof redirect === "undefined") {
        router.push("/");
        return;
      }

      let url;

      try {
        url = new URL(redirect, location.origin);
      } catch (err) {
        console.error("Redirect link error", err);
        return;
      }

      if (url.origin !== location.origin) {
        // don't redirect on other services
        return;
      }

      if (url.pathname === "/") {
        router.push("/");
        return;
      }

      const path = url.pathname + url.search;

      router.push(path);
    }
  }, [user, router]);

  const resendVarification = async () => {
    await fetcher("/api/auth/resend-verify-email", {
      method: "POST",
      json: {
        email: router.query.verify_email,
      },
    });
  };

  const onSubmit = async (formValues) => {
    dispatch({ type: Actions.StartLoading });

    const { error } = await signIn({
      email: formValues.email,
      password: formValues.password,
    });

    console.log(error);

    if (error) {
      if (error.code === AUTH_ERROR_CODE.NOT_FOUND_ACCOUNT) {
        return createSnackbar("Please enter a valid email address.", "error");
      }
      if (error.code === AUTH_ERROR_CODE.NEED_VERIFICATION_EMAIL) {
        // resend verification email
        try {
          // await fetcher("/api/auth/resend-verify-email", {
          //   method: "POST",
          //   json: {
          //     email: formValues.email,
          //   },
          // });
          createSnackbar("Not verified", "error");
          dispatch({
            type: Actions.SentEmail,
          });
          router.replace(`${Routes.Login}?verify_email=${formValues.email}`);
        } catch (err) {
          if ("error" in err === false) {
            console.error("Unexpected client error", err);
          }

          dispatch({
            type: Actions.SetError,
            value: err.errorInfo?.message || "Something went wrong",
          });
        }
        return;
      }
      if (error.code === AUTH_ERROR_CODE.WRONG_PASSWORD) {
        return createSnackbar("Wrong password", "error");
      }
      return createSnackbar("Something went wrong", "info");
    }

    createSnackbar("Successful login", "success");
  };

  const loginInWithGoogle = async () => {
    const { error } = await signInWithGoogle();
    if (error) {
      dispatch({
        type: Actions.SetError,
        value: error.message ?? "Something went wrong",
      });
    }
  };

  const loginInWithFacebook = async () => {
    const { error } = await signInWithFacebook();
    if (error) {
      dispatch({
        type: Actions.SetError,
        value: error.message ?? "Something went wrong",
      });
    }
  };

  useEffect(() => {
    router.asPath === Routes.Login &&
      dispatch({
        type: Actions.Login,
      });
  }, [router]);

  return (
    <div className={classes.root}>
      <AppHead title={LOGIN_SEO.title} description={LOGIN_SEO.description}>
        <title>{LOGIN_SEO.title}</title>
        <meta name="description" content={LOGIN_SEO.description} />
      </AppHead>

      <BackgroundPattern />

      <Box className={`${classes.box} `}>
        <Paper className={classes.paper}>
          {state.pageType === PageTypes.Login &&
            router.asPath.split("?")[0] === Routes.Login && (
              <>
                <Box className={classes.topLinkTitle}>
                  <Typography variant="h2">Sign In</Typography>
                  <Box className={classes.note}>
                    <Box mr={1}>
                      <Typography variant="body2">New to Luner?</Typography>
                    </Box>
                    <NextLink href="/signup" className={classes.disabled}>
                      <Link
                        className={cx(classes.navItem, classes.disabled)}
                        color="inherit"
                        variant="body1"
                        href="/signup">
                        <Typography variant="body1">Sign Up!</Typography>
                      </Link>
                    </NextLink>
                  </Box>
                </Box>

                {state.loginError && (
                  <Message text={state.loginError} mb={4} width="100%" />
                )}
                <LoginForm onSubmit={onSubmit} />

                {/* <Box className={classes.note}>
                  <Typography variant="body2">
                    <NextLink href="/forgot-password">
                      <Link
                        className={classes.navItem}
                        color="inherit"
                        variant="body1"
                        href="/forgot-password">
                        Forgot Password?
                      </Link>
                    </NextLink>
                  </Typography>
                  <NextLink href="/signup">
                    <Link
                      className={classes.navItem}
                      color="inherit"
                      variant="body1"
                      href="/signup">
                      <MainButton title="Sign Up!" buttonPadding="12px 90px" />
                    </Link>
                  </NextLink>
                </Box> */}

                <Socials
                  signInWithGoogle={loginInWithGoogle}
                  signInWithFacebook={loginInWithFacebook}
                />

                {/* <Typography variant="body2">
                  <NextLink href="/forgot-password">
                    <Link
                      className={classes.navItem}
                      color="inherit"
                      variant="body1"
                      href="/forgot-password">
                      Forgot Password?
                    </Link>
                  </NextLink>
                </Typography> */}
              </>
            )}
          {state.pageType === PageTypes.EmailSent && router.query.verify_email && (
            <>
              <Typography variant="h2" className={classes.textLeft} mb={5}>
                Account was not verified!
              </Typography>

              <Typography variant="body2" className={classes.textLeft} mb={5}>
                We have sent you an email with a link.
                <br />
                Follow it to confirm your mail.
              </Typography>

              <Box onClick={resendVarification}>
                <MainButton
                  title="Resend Verification Mail"
                  buttonPadding="12px 90px"
                  fullWidth
                />
              </Box>
              <Box className={classes.note} mt={1}>
                <NextLink href={Routes.Login}>
                  <Link
                    className={classes.navItem}
                    color="inherit"
                    variant="body1"
                    href={Routes.Login}>
                    <Typography variant="body2">Back to sign in</Typography>
                  </Link>
                </NextLink>
              </Box>
            </>
          )}
          <Box className={classes.copyright}>
            <Typography variant="body2" color="textSecondary">
              Copyright © 2021 Luner.com All Rights Reserved.
            </Typography>
          </Box>
        </Paper>
      </Box>
      <LoginInfo />
    </div>
  );
}
