import React from "react";
import {
  useForm,
  FormProvider as RHFormProvider,
  UseFormReturn,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export interface FormValues {
  [key: string]: string;
}

export interface FormProviderProps {
  defaultValues: FormValues;
  onSubmit: (values: FormValues) => void;
  FormProps: React.ComponentPropsWithoutRef<"form">;
  ValidationSchema: Yup.AnyObjectSchema;
  children?:
    | ((props: UseFormReturn<FormValues>) => React.ReactNode)
    | React.ReactNode;
}

// TODO: add global form error?(i.e. error response from server)
export const FormProvider = (props: FormProviderProps) => {
  const {
    defaultValues,
    children,
    onSubmit = () => {},
    FormProps,
    ValidationSchema,
  } = props;

  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(ValidationSchema),
  });

  const { handleSubmit } = formMethods;

  return (
    <RHFormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate {...FormProps}>
        {typeof children === "function" ? children(formMethods) : children}
      </form>
    </RHFormProvider>
  );
};
